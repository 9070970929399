<script setup lang="ts">
import { useGameEventsStore, type TapTournament } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Container from './common/Container.vue'
import ListItem from './common/ListItem.vue'
import EventCategory from './event/EventCategory.vue'
import IconCheck from './icons/IconCheck.vue'
import IconCupSolid from './icons/IconCupSolid.vue'
import IconMore from './icons/IconMore.vue'

const userEventStore = useGameEventsStore()
const { getCategories } = userEventStore
const { tapEventCategories } = storeToRefs(userEventStore)

const { t } = useI18n()

const selectedTapTournamentId = ref<number | null>(null)

onMounted(async () => {
  await getCategories()
})

const selectCategory = (tapTournament: TapTournament) => {
  console.log('category: ', tapTournament.id)
  selectedTapTournamentId.value = tapTournament.id
}
const closeCategory = () => {
  selectedTapTournamentId.value = null
}
</script>

<template>
  <Container>
    <!-- categories list  -->
    <div v-if="!selectedTapTournamentId">
      <div class="ma-auto" style="max-width: 80%">
        <h2 class="mt-4 page-title" v-html="t('eventTab.categories.title')"></h2>
        <h3 class="page-sub-title mt-5">{{ t('eventTab.categories.description') }}</h3>
      </div>

      <!-- categories list  -->
      <div v-if="tapEventCategories?.length > 0" class="list-container mt-8">
        <ListItem
          v-for="category in tapEventCategories"
          :key="category.id"
          :title="category.name"
          @click.stop="selectCategory(category)"
        >
          <template #prepend>
            <IconCupSolid
              class="text-dark-green"
              style="width: 48px; height: 48px; max-width: 48px"
            />
          </template>
          <template #subtitle>
            <div class="subtitle">subtitle</div>
          </template>
          <template #append>
            <IconCheck v-if="false" style="width: 25px; height: 25px" />
            <IconMore v-else style="width: 21px; height: 21px; stroke-width: 2px" />
          </template>
        </ListItem>
      </div>
      <ListItem v-else class="mt-8">
        {{ t('eventTab.categories.dontHaveCategories') }}
      </ListItem>
    </div>

    <div v-else>
      <EventCategory @close="closeCategory" :tapTournamentId="selectedTapTournamentId"/>
    </div>
  </Container>
</template>

<style scoped></style>
