<script setup lang="ts">
import { useGameEventsStore } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionBtn from '../common/ActionBtn.vue'
import BtnText from '../common/BtnText.vue'
import EventTimer from '../common/EventTimer.vue'
import IconMore from '../icons/IconMore.vue'
import PrizeTable from './PrizeTable.vue'

const { t } = useI18n()

const userEventStore = useGameEventsStore()
const { checkUserRegistration, registerForTournament } = userEventStore
const { isUserRegisteredForEvent, tournamentRound } = storeToRefs(userEventStore)

const isShowHowToParticipate = ref(false)
const toggleHowToParticipateModal = () => {
  isShowHowToParticipate.value = !isShowHowToParticipate.value
}

const hasTimeLeft = computed(() => {
  return true
  //   if (!tournamentRound.value?.ended_at) return false

  //   return new Date(tournamentRound.value?.ended_at) > new Date()
})

const registerAfterEventStarted = computed(
  //   () => !hasTimeLeft.value && tournamentRound.value?.can_register_after_registration_time_passed
  () => false
)

const registrationFinished = () => {
  console.log('registrationFinished: ')
}

</script>

<template>
  <div class="">
    <div style="max-width: 95%" class="ma-auto">
      <h2 class="page-title mt-12" style="font-size: 30px" v-html="t('eventTab.title')"></h2>

      <h3 class="page-sub-title mt-5">
        {{ t('eventTab.description') }}
      </h3>
    </div>

    <!-- Timer → Event starts in -->
    <div v-if="tournamentRound?.is_user_registration_available" class="mt-8 timer-widget">
      <div>{{ t('eventTab.register.eventStarts') }}</div>
      <EventTimer
        :endDate="tournamentRound?.registration_end_date"
        :size="30"
        @finished="registrationFinished"
      />
    </div>

    <ActionBtn
      v-if="!isUserRegisteredForEvent"
      class="mt-8"
      :text="t('eventTab.register.registerButton')"
      @click="registerForTournament"
    >
    </ActionBtn>
    <!-- <ActionBtn
      v-if="isUserRegisteredForEvent"
      class="mt-8"
      :text="t('eventTab.register.youAreRegistered')"
    >
    </ActionBtn> -->

    <div v-else-if="registerAfterEventStarted" class="mt-8 page-mid-title">
      {{ t('eventTab.register.notRegisteredButStillCanJoin') }}
    </div>

    <div v-else-if="!tournamentRound?.is_user_registration_available" class="mt-8 page-mid-title">
      {{ t('eventTab.register.notRegistered') }}
    </div>

    <PrizeTable />

    <div class="mt-8 flex justify-center">
      <BtnText @click="toggleHowToParticipateModal">
        🔓 {{ t('eventTab.howToParticipate') }}
        <template #appendIcon>
          <IconMore
            style="width: 14px; height: 14px"
            :class="{ rotate90: isShowHowToParticipate }"
          />
        </template>
      </BtnText>
    </div>

    <div class="mt-4" v-if="isShowHowToParticipate">
      <ol class="how-to-participate">
        <li>{{ t('eventTab.participateCondition.first') }}</li>
        <li>
          {{ t('eventTab.participateCondition.second.title') }}
          <ul>
            <li>{{ t('eventTab.participateCondition.second.sub1') }}</li>
            <li>{{ t('eventTab.participateCondition.second.sub2') }}</li>
          </ul>
        </li>
      </ol>
    </div>

    <div v-if="isShowHowToParticipate" class="my-4 flex justify-center">
      <BtnText @click="toggleHowToParticipateModal">
        {{ t('common.hide') }}
        <template #appendIcon>
          <IconMore style="width: 14px; height: 14px; transform: rotate(90deg)" />
        </template>
      </BtnText>
    </div>

    <ActionBtn
      v-if="registerAfterEventStarted"
      class="mt-8"
      :text="t('eventTab.register.registerButton')"
      @click="registerForTournament"
    >
    </ActionBtn>
  </div>
</template>

<style scoped>
.timer-widget {
  text-align: center;
  font-size: 24px;
}

.how-to-participate {
  padding-left: 30px;
  counter-reset: list;
}

.how-to-participate > li {
  list-style: none;
}

.how-to-participate > li:before {
  content: counter(list) ') ';
  counter-increment: list;
}

.how-to-participate > ol > ul {
  list-style-type: none;
}

.how-to-participate > ol > ul > li:before {
  content: '- ';
}
</style>
