import router from '@/router'
import { useAuthStore } from '@/stores/auth'
import type { GameFinishData, GameStartData } from '@/stores/catchBullGame'
import type { CatchTheBullBoostType, CatchTheBullGameBoost } from '@/stores/catchTheBullShop'
import type { TapTournament } from '@/stores/gameEvents'
import { notify } from '@/stores/message'
import type { Bet } from '@/stores/tournament'
import type { Task } from '@/stores/userTasks'
import axios from 'axios'
import { storeToRefs } from 'pinia'

export type ApiResponse<T> = {
  message: string
  success: boolean
  data: T
}

export const useApi = () => {
  const baseURL = import.meta.env.VITE_APP_BASE_URL

  const authStore = useAuthStore()
  const { userLocale } = storeToRefs(authStore)

  const createObject: any = {
    baseURL, //: 'https://pika-great-neatly.ngrok-free.app',
    timeout: 60000,
    // withCredentials: true,
    withXSRFToken: true,
    // xsrfCookieName: 'XSRF-TOKEN',
    // xsrfHeaderName: 'X-XSRF-TOKEN',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Accept-Language': userLocale.value
    }
  }

  if (authStore?.token) {
    createObject.headers.Authorization = `Bearer ${authStore.token}`
  }

  const instance = axios.create(createObject)

  instance.interceptors.response.use(
    (response) => {
      if (response.data?.message) {
        const messageType = response.data?.success ? 'success' : 'error'

        if (response.data.message) notify(response.data.message, messageType)
      }

      return response
    },
    (error) => {
      if (error?.response?.status === 503) {
        router.push({ name: 'maintenance' })

        return Promise.reject(error)
      }

      notify(error?.response?.message || 'common.serverError', 'error')
      return Promise.reject(error)
    }
  )

  return instance
}

export async function energyBoostUpgrade() {
  await useApi().post('/api/energy-boost-upgrade')
}

export async function getAvailableEnergyBoost() {
  return (await useApi().get('/api/energy-boost-upgrade')).data
    .data as App.DTO.Energy.TapEnergyLevelData
}

export async function getUserTasks() {
  return (await useApi().get('/api/tasks')).data.data as Task[]
}

export async function getUserCompletedTaskIds() {
  return (await useApi().get('/api/tasks/completed')).data.data as number[]
}

export async function completeTask(taskId: number, payload: any) {
  return (
    await useApi().post('/api/tasks/complete', {
      task_id: taskId,
      payload
    })
  ).data as ApiResponse<any>
}

export async function getJoinChannelTasks() {
  return (await useApi().get('/api/tasks/join-channel')).data.data as number[]
}

export async function storeUserWallet(wallet: string) {
  return (await useApi().post('/api/wallet', { wallet })).data.data
}

export async function authorizeBroadcast(data: { socket_id: string; channel_name: string }) {
  return await useApi().post('/api/broadcasting/auth', data)
}

/**
 * Tap Tournament
 */
export async function getTapTournaments() {
  return (await useApi().get('/api/tap-tournaments')).data.data as TapTournament[]
}

export async function getTapTournamentRound(tournamentId: number) {
  return (await useApi().get(`/api/tap-tournament-round/${tournamentId}`)).data.data as App.DTO.TapTournament.TapTournamentRoundData
}

export async function getActiveEventPhase(eventId: number) {
  return (await useApi().get(`/api/events/${eventId}/active-phase`)).data.data as any
}

export async function getPhaseUsers(phaseId: number) {
  return (await useApi().get(`/api/phases/${phaseId}/users`)).data.data as any
}

export async function getCurrentUserRank() {
  return (await useApi().get('/api/user-rank')).data.data as App.DTO.Rank.UserRankData
}

export async function getUserPassiveIncomeEarned() {
  return (await useApi().get('/api/user-passive-income-earned')).data.data
}

export async function getPredictionTournaments() {
  return (await useApi().get('/api/prediction-tournaments')).data
    .data as App.DTO.PredictionTournament.PredictionTournamentData[]
}

export async function getTournamentCategorySchedule(tournamentCategoryId: number) {
  return (await useApi().get(`/api/prediction-tournaments/${tournamentCategoryId}/schedule`)).data
    .data as App.DTO.PredictionTournament.PredictionTournamentCategoryScheduleData[]
}

export async function getPredictionTournamentCurrencies(tournamentId: number) {
  return (await useApi().get(`/api/prediction-tournaments/${tournamentId}/currencies`)).data
    .data as App.DTO.PredictionTournament.PredictionTournamentCurrencyData[]
}

export async function registerUserPredictionForCurrency(currencyId: number, bet: Bet) {
  return (
    await useApi().post(`/api/prediction-tournament-currency/${currencyId}/register-user-bet`, {
      bet
    })
  ).data.data
}

export async function getPredictionTournament(tournamentId: number) {
  return (await useApi().get(`/api/prediction-tournaments/${tournamentId}`)).data
    .data as App.DTO.PredictionTournament.PredictionTournamentData
}

export async function getPredictionTournamentCurrencyRounds(tournamentId: number) {
  return (await useApi().get(`/api/prediction-tournaments/${tournamentId}/active-rounds`)).data
    .data as App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData[]
}

export type PredictionPayload = 'up' | 'down' | number

export async function registerForPredictionTournament(
  roundId: number,
  prediction: PredictionPayload
) {
  return (
    await useApi().post(`/api/prediction-tournament-currency-round/${roundId}/register-user`, {
      prediction
    })
  ).data.data as any
}

export async function getTournamentRating() {
  return (await useApi().get('/api/prediction-tournament-rating')).data
    .data as App.DTO.PredictionTournament.PredictionTournamentUsersRatingData[]
}

export async function getTournamentsScore() {
  return (await useApi().get('/api/prediction-tournaments-score')).data
    .data as App.DTO.PredictionTournament.PredictionTournamentsScoreData[]
}

export async function getUserDailyCombo() {
  return (await useApi().get('/api/user-daily-combo')).data.data
}

/**
 * Single hand tournament
 */
export async function registerUserBet(
  data: App.DTO.SingleHandTournament.SingleHandTournamentUserBidData
) {
  return (await useApi().post('/api/single-hand-tournament/register-user-bet', data)).data.data
}

export async function closeUserBet(
  data: App.DTO.SingleHandTournament.SingleHandTournamentCloseBetData
) {
  return (await useApi().post('/api/single-hand-tournament/close-user-bet', data)).data
    .data as App.DTO.SingleHandTournament.SingleHandTournamentBetResultData
}

/**
 * Catch the bull
 */
export async function fetchBoostsAvailable() {
  return (await useApi().get('/api/catch-the-bull-game-boosts')).data
    .data as CatchTheBullGameBoost[]
}

export async function fetchCatchTheBullUserGame() {
  return (await useApi().get('/api/catch-the-bull-game-user')).data
    .data as App.DTO.CatchTheBullGame.CatchTheBullUserData
}

export async function startCatchTheBullGame(data: GameStartData) {
  return await useApi().post('/api/catch-the-bull-game-start', data)
}

export async function finishCatchTheBullGame(data: GameFinishData) {
  return await useApi().post('/api/catch-the-bull-game-finish', data)
}
export async function collectTicketReward() {
  return await useApi().get('/api/raffle-ticket-fragments-combine')
}

export async function fetchBoostInvoiceLink(boostType: CatchTheBullBoostType, quantity: number) {
  return (
    await useApi().post('/api/catch-the-bull-game-boosts/invoice-link', {
      boostType,
      quantity
    })
  ).data.data as string
}

/**
 * Raffle
 */

export async function fetchRaffle() {
  return (await useApi().get('/api/raffle')).data.data
}
