import {
  fetchCatchTheBullUserGame,
  finishCatchTheBullGame,
  startCatchTheBullGame
} from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type GameStartData = App.DTO.CatchTheBullGame.CatchTheBullGameStartData
export type GameFinishData = App.DTO.CatchTheBullGame.CatchTheBullGameEndData

export const useCatchBullStore = defineStore('catchBull', () => {
  const maxTickets = ref(1000)
  const strike = ref(0) //count of bulls catches in a row

  const ticketFragmentsTotalCount = ref(0)
  const ticketFragmentsGameCount = ref(0)
  const maxTries = ref(0)
  const triesLeft = ref(0)
  const purchasedTries = ref(0)
  // Boosts
  const bombsFreeBoostsCount = ref(0)
  const doublePointsBoostCount = ref(0)
  const triplePointsBoostCount = ref(0)
  const slowdownBoostsCount = ref(0)

  // game modes
  const isBombFreeMode = ref(false)
  const isSlowDownMode = ref(false)
  const isDoubleBoostMode = ref(false)
  const isTripleBoostMode = ref(false)

  const ticketsMultiplyCoefficient = ref(1)

  const getAvailableBoosts = async () => {
    try {
      const data = await fetchCatchTheBullUserGame()
      ticketFragmentsTotalCount.value = data.ticketFragmentsCount
      bombsFreeBoostsCount.value = data.bombsFreeBoostsCount
      doublePointsBoostCount.value = data.doublePointsBoostCount
      triplePointsBoostCount.value = data.triplePointsBoostCount
      slowdownBoostsCount.value = data.slowdownBoostsCount
      maxTries.value = data.maxTries
      triesLeft.value = data.triesLeft
      purchasedTries.value = data.purchasedTries
    } catch (error) {
      // ticketFragmentsTotalCount.value = 50
      // bombsFreeBoostsCount.value = 1
      // doublePointsBoostCount.value = 1
      // triplePointsBoostCount.value = 1
      // slowdownBoostsCount.value = 1
      // maxTries.value = 4
      // triesLeft.value = 5
      // purchasedTries.value = 6
    }
  }

  async function startGame(data: GameStartData) {
    await startCatchTheBullGame(data)
  }

  async function finishGame(data: GameFinishData) {
    await finishCatchTheBullGame(data)
  }

  return {
    ticketFragmentsTotalCount,
    ticketFragmentsGameCount,
    maxTickets,
    strike,
    bombsFreeBoostsCount,
    doublePointsBoostCount,
    triplePointsBoostCount,
    slowdownBoostsCount,
    maxTries,
    triesLeft,
    ticketsMultiplyCoefficient,
    isBombFreeMode,
    isSlowDownMode,
    isDoubleBoostMode,
    isTripleBoostMode,
    purchasedTries,
    getAvailableBoosts,
    startGame,
    finishGame
  }
})
