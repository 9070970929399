import {
  getTapTournamentRound as fetchTapTournamentRound,
  getActiveEventPhase,
  getPhaseUsers as getPhaseUsersApi,
  getTapTournaments
} from '@/application/services/useApi'
import WebApp from '@twa-dev/sdk'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { notify } from './message'

type TournamentRound = Omit<
  App.DTO.TapTournament.TapTournamentRoundData,
  'registration_start_date' | 'registration_end_date'
> & {
  registration_start_date: Date
  registration_end_date: Date
}

export type PhaseUsers = { id: number; name: string; balance: number; profile_image_url: string }
export type TapTournament = App.DTO.TapTournament.TapTournamentData
export type TapTournamentRound = App.DTO.TapTournament.TapTournamentRoundData

export const useGameEventsStore = defineStore('gameEvents', () => {
  const tapEventCategories = ref<TapTournament[]>([])
  const isUserRegisteredForEvent = ref(false)
  const tournamentRound = ref<TournamentRound | null>(null)
  const activeEventPhase = ref<TournamentRound | null>(null)
  const phaseUsers = ref<PhaseUsers[]>([])
  const fetchingResults = ref(true)

  const hasNewEvent = computed(() =>
    activeEventPhase.value ? !activeEventPhase.value?.is_user_registered : false
  )

  async function checkUserRegistration(tournamentId: number) {
    const data = await fetchTapTournamentRound(tournamentId)

    tournamentRound.value = {
      tournament_id: data.tournament_id,
      registration_start_date: new Date(data.registration_start_date),
      registration_end_date: new Date(data.registration_end_date),
      is_user_registered: data.is_user_registered,
      is_user_registration_available: data.is_user_registration_available,
      credits_earned: data.credits_earned,
      can_register_after_registration_time_passed: data.can_register_after_registration_time_passed,
      round_phase: data.round_phase,
      tap_tournament: data.tap_tournament
    }

    isUserRegisteredForEvent.value = tournamentRound.value?.is_user_registered || false
  }

  function registerForTournament() {
    const invoiceLink = tournamentRound.value?.tap_tournament?.registration_invoice_url

    if (!invoiceLink) return

    WebApp.openInvoice(invoiceLink, (status) => {
      if (status === 'failed') {
        notify('Failed to pay a registration fee', 'error')
      } else if (status === 'cancelled') {
        notify('Registration cancelled', 'error')
      }
    })
  }

  async function getTapTournamentRound(tapTournamentId: number) {
    await checkUserRegistration(tapTournamentId)
  }

  async function getPhaseUsers(phaseId: number) {
    fetchingResults.value
    try {
      phaseUsers.value = await getPhaseUsersApi(phaseId)
    } catch (error) {
      // phaseUsers.value = [
      //   { id: 1, name: 'name-1', balance: 2545, profile_image_url: '' },
      //   { id: 2, name: 'name-2', balance: 355, profile_image_url: '' },
      //   { id: 3, name: 'name-3', balance: 34634, profile_image_url: '' },
      //   { id: 4, name: 'name-4', balance: 254564345, profile_image_url: '' }
      // ]
    } finally {
      fetchingResults.value = false
    }
  }

  async function getCategories() {
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve([
    //       { id: 1, bid: 100, title: '100 stars (friends invites applicable)' },
    //       { id: 2, bid: 500, title: '500 stars (friends invites applicable)' },
    //       { id: 3, bid: 1000, title: '1000 stars (friends invites not applicable)' },
    //       { id: 4, bid: 5000, title: '5000 stars (friends invites not applicable)' }
    //     ])
    //   }, 1000)
    // }).then((categories) => {
    //   tapEventCategories.value = [...(categories as any)]
    // })
    await getTapTournaments().then((categories) => {
      tapEventCategories.value = [...(categories as any)]
    })
  }

  const isRegistrationAvailable = computed(() => true)

  const infoBannerTitle = computed(() => {
    if (isRegistrationAvailable.value) return 'homeTab.info.newEvent'
    // if (isEventPhase.value) return 'homeTab.info.started'
    return 'homeTab.info.stayTuned'
  })

  const infoBannerSubtitle = computed(() => {
    if (isRegistrationAvailable.value) return ''
    // if (isEventPhase.value) return ''
    return 'homeTab.info.newEventsAreComing'
  })

  const infoBannerTimerText = computed(() => {
    if (isRegistrationAvailable.value) return 'homeTab.info.startsIn'
    // if (isEventPhase.value) return 'homeTab.info.endsIn'
    return ''
  })

  const infoBannerTimerDate = computed(() => {
    if (isRegistrationAvailable.value) return activeEventPhase.value?.registration_end_date
    // if (isEventPhase.value) return activeEventPhase.value?.ended_at

    return undefined
  })

  return {
    isUserRegisteredForEvent,
    checkUserRegistration,
    tournamentRound,
    registerForTournament,
    getTapTournamentRound,
    activeEventPhase,
    getPhaseUsers,
    phaseUsers,
    hasNewEvent,
    fetchingResults,
    getCategories,
    tapEventCategories,
    isRegistration: isRegistrationAvailable,
    infoBannerTitle,
    infoBannerSubtitle,
    infoBannerTimerText,
    infoBannerTimerDate
  }
})
