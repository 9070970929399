import { fetchBoostInvoiceLink, fetchBoostsAvailable } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type CatchTheBullGameBoost = App.DTO.CatchTheBullGame.CatchTheBullGameBoostsData
export type CatchTheBullBoostType = App.Services.CatchTheBull.Enums.CatchTheBullBoostTypes

export const useCatchTheBullShopStore = defineStore('catchTheBullShop', () => {
  const boostsAvailable = ref<CatchTheBullGameBoost[]>([])

  async function getBoostsAvailable() {
    try {
      boostsAvailable.value = await fetchBoostsAvailable()
    } catch (error) {
      // boostsAvailable.value = [
      //   {
      //     name: 'catchTheBullGame.boosts.doublePoints',
      //     description: 'catchTheBullGame.boosts.doubleBoostDescription',
      //     batchSize: 1,
      //     batchSizeMax: 100,
      //     batchStep: 1,
      //     priceForSingleBatch: 5,
      //     type: 'catch_the_bull_game_double_points_boost'
      //   },
      //   {
      //     name: 'catchTheBullGame.boosts.additionalTry',
      //     description: 'catchTheBullGame.boosts.doubleBoostDescription',
      //     batchSize: 1,
      //     batchSizeMax: 100,
      //     batchStep: 1,
      //     priceForSingleBatch: 5,
      //     type: 'catch_the_bull_game_additional_try_boost'
      //   },
      //   {
      //     name: 'catchTheBullGame.boosts.removeBombs',
      //     description: 'catchTheBullGame.boosts.doubleBoostDescription',
      //     batchSize: 1,
      //     batchSizeMax: 100,
      //     batchStep: 1,
      //     priceForSingleBatch: 5,
      //     type: 'catch_the_bull_game_remove_bombs_boost'
      //   },
      //   {
      //     name: 'catchTheBullGame.boosts.slowDown',
      //     description: 'catchTheBullGame.boosts.doubleBoostDescription',
      //     batchSize: 1,
      //     batchSizeMax: 100,
      //     batchStep: 1,
      //     priceForSingleBatch: 5,
      //     type: 'catch_the_bull_game_slow_down_boost'
      //   },
      //   {
      //     name: 'catchTheBullGame.boosts.triplePoints',
      //     description: 'catchTheBullGame.boosts.doubleBoostDescription',
      //     batchSize: 1,
      //     batchSizeMax: 100,
      //     batchStep: 1,
      //     priceForSingleBatch: 5,
      //     type: 'catch_the_bull_game_triple_points_boost'
      //   },
      //   {
      //     name: 'catchTheBullGame.boosts.tenFragments',
      //     description: 'catchTheBullGame.boosts.doubleBoostDescription',
      //     batchSize: 1,
      //     batchSizeMax: 100,
      //     batchStep: 1,
      //     priceForSingleBatch: 5,
      //     type: 'raffle_ticket_fragment_invoice'
      //   }
      // ]
    }
  }

  async function getBoostInvoiceLink(
    boostType: CatchTheBullBoostType,
    quantity: number
  ): Promise<string> {
    return await fetchBoostInvoiceLink(boostType, quantity)
  }

  return {
    boostsAvailable,
    getBoostsAvailable,
    getBoostInvoiceLink
  }
})
