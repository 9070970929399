<script setup lang="ts">
import { useGameEventsStore } from '@/stores/gameEvents'
import { amountFormatWithSymbol } from '@/utils/amountFormat'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import EventTimer from '../common/EventTimer.vue'
import IconBalance from '../icons/IconBalance.vue'
import PrizeTable from './PrizeTable.vue'
import WinnersList from './WinnersList.vue'

const { t } = useI18n()

const gameEventStore = useGameEventsStore()
const { getPhaseUsers } = gameEventStore
const { activeEventPhase, phaseUsers, fetchingResults } = storeToRefs(gameEventStore)
const phaseId = 8

const shoFullDescription = ref(false)

const registrationFinished = () => {
  console.log('registrationFinished: ')
}

onMounted(async () => {
  await getPhaseUsers(phaseId)
})
</script>

<template>
  <div class="">
    <div style="max-width: 95%" class="ma-auto">
      <h2 class="mt-4 page-title" style="font-size: 30px" v-html="t('eventTab.title')"></h2>

      <h3 class="page-sub-title mt-4">{{ t('eventTab.description') }}:</h3>
    </div>

    <template v-if="shoFullDescription">
      <PrizeTable />
    </template>

    <div class="text-center text-primary" @click="shoFullDescription = !shoFullDescription">
      {{ shoFullDescription ? t('common.less') : t('common.more') }}...
    </div>

    <!-- Amount of Coins earned  -->
    <div class="coins mt-12 text-center">{{ t('eventTab.tapPhase.amountOfCoinsEarned') }}</div>
    <div class="coins flex justify-center items-center">
      <IconBalance class="coins-icon" />
      <div class="coins-value">
        {{ amountFormatWithSymbol(activeEventPhase?.credits_earned || 0) }}
      </div>
    </div>

    <!-- Event Ends in: {timer - 72hrs};  -->
    <div class="mt-8 flex justify-between items-center">
      <div class="">{{ t('eventTab.tapPhase.eventEndsIn') }}:</div>
      <EventTimer :end-date="activeEventPhase?.registration_end_date" @finished="registrationFinished" />
    </div>

    <!-- - Leader board with user’s current ranking (position, photo+username, coins amount) → first 3 users -->
    <!-- should get unique icons to highlight their position. -->
    <div class="">
      <WinnersList
        :users="phaseUsers"
        :loading="fetchingResults"
        noUsersText="userList.noUsersYet"
      />
    </div>
  </div>
</template>

<style scoped>
.coins {
  font-size: 24px;
}
.coins-value {
  font-size: 48px;
  font-weight: bold;
}
.coins-icon {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}
</style>
