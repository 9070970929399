<script setup lang="ts">
import BtnPrimary from '@/components/common/BtnPrimary.vue'
import Container from '@/components/common/Container.vue'
import DialogModal from '@/components/common/DialogModal.vue'
import FullWindowModal from '@/components/common/FullWindowModal.vue'
import IconBack from '@/components/icons/IconBack.vue'
import IconStar from '@/components/icons/IconStar.vue'
import { useCatchBullStore } from '@/stores/catchBullGame'
import { useCatchTheBullShopStore, type CatchTheBullGameBoost } from '@/stores/catchTheBullShop'
import { notify } from '@/stores/message'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

defineEmits(['close'])

const { t } = useI18n()

const selectedBoost = ref<CatchTheBullGameBoost | null>(null)
const quantityToBuy = ref(1)
const buying = ref(false)

const catchBullGame = useCatchBullStore()
const catchTheBullShopStore = useCatchTheBullShopStore()
const { getBoostsAvailable, getBoostInvoiceLink } = catchTheBullShopStore
const { boostsAvailable } = storeToRefs(catchTheBullShopStore)

const availableFroPurchase = computed(() => {
  return selectedBoost.value?.batchSizeMax !== 0
})

const buyBoost = async (boost: CatchTheBullGameBoost) => {
  if (!availableFroPurchase.value) return

  buying.value = true

  const invoiceLink = await getBoostInvoiceLink(boost.type, quantityToBuy.value * boost.batchSize)
  await Promise.all([catchBullGame.getAvailableBoosts(), getBoostsAvailable])

  notify('Success', 'success')
  //   WebApp.openInvoice(invoiceLink, (status) => {
  //     buying.value = false
  //     selectedBoost.value = null
  //     if (status === 'paid') {
  //       setTimeout(() => {
  //         getBoostsAvailable()
  //       }, 5000)
  //     }
  //   })
}

onMounted(async () => {
  await getBoostsAvailable()
})

const selectBoost = (boost: CatchTheBullGameBoost) => {
  if (!availableFroPurchase.value) return

  quantityToBuy.value = 1
  selectedBoost.value = boost
}

const decreaseItemsCount = () => {
  if (!selectedBoost.value) return

  if (quantityToBuy.value > 1) {
    quantityToBuy.value--
  }
}

const increaseItemsCount = () => {
  if (!selectedBoost.value) return

  if (quantityToBuy.value < selectedBoost.value.batchSizeMax) {
    quantityToBuy.value++
  }
}
</script>

<template>
  <FullWindowModal>
    <Container>
      <div class="flex justify-between items-center mt-6">
        <IconBack
          class="text-light-green"
          style="width: 20px; height: 20px"
          @click="$emit('close')"
        />
        <!-- <IconHelpOld class="text-primary" style="width: 26px; height: 26px" /> -->
      </div>

      <div class="ma-auto" style="max-width: 70%">
        <h2 class="page-title">{{ t('catchTheBullGame.shop') }}!</h2>
        <h3 class="page-sub-title mt-5">{{ t('catchTheBullGame.shopDescription') }}</h3>
      </div>

      <!-- Generate buttons from boosts list -->
      <div class="shop-items-container">
        <div
          v-for="boost in boostsAvailable"
          :key="boost.type"
          class="shop-item"
          :class="[
            boost.type === 'catch_the_bull_game_double_points_boost' ||
            boost.type === 'catch_the_bull_game_triple_points_boost'
              ? 'boost'
              : '',
            boost.type === 'catch_the_bull_game_slow_down_boost' ? 'down' : '',
            boost.type === 'catch_the_bull_game_remove_bombs_boost' ? 'bombs' : '',
            boost.type === 'raffle_ticket_fragment_invoice' ? 'fragments' : '',
            boost.type === 'catch_the_bull_game_additional_try_boost' ? 'try' : ''
          ]"
          @click="selectBoost(boost)"
        >
          <div class="shop-items-title">{{ t(boost.name) }}</div>
          <div class="">
            <img
              v-if="boost.type === 'catch_the_bull_game_double_points_boost'"
              src="/img/games/navigation_boost2.webp"
              class="ofi"
              style="width: 60px; height: 60px"
            />
            <!-- <IconBolt
              v-if="
                boost.type === 'catch_the_bull_game_double_points_boost' ||
                boost.type === 'catch_the_bull_game_triple_points_boost'
              "
              class="text-yellow"
              style="width: 33px; height: 33px"
            /> -->
            <img
              v-if="boost.type === 'catch_the_bull_game_triple_points_boost'"
              src="/img/games/navigation_boost3.webp"
              class="ofi"
              style="width: 60px; height: 60px"
            />
            <!-- <IconBolt
              v-if="
                boost.type === 'catch_the_bull_game_double_points_boost' ||
                boost.type === 'catch_the_bull_game_triple_points_boost'
              "
              class="text-yellow"
              style="width: 33px; height: 33px"
            /> -->
            <img
              v-if="boost.type === 'catch_the_bull_game_slow_down_boost'"
              src="/img/games/snow.webp"
              class="ofi"
              style="width: 60px; height: 60px"
            />
            <!-- <IconSnow
              v-if="boost.type === 'catch_the_bull_game_slow_down_boost'"
              style="width: 33px; height: 33px"
            /> -->
            <img
              v-if="boost.type === 'catch_the_bull_game_remove_bombs_boost'"
              src="/img/games/navigation_bomb.webp"
              class="ofi"
              style="width: 60px; height: 60px"
            />
            <!-- <IconDrop
              v-if="boost.type === 'catch_the_bull_game_remove_bombs_boost'"
              style="width: 33px; height: 33px"
            /> -->
            <img
              v-if="boost.type === 'raffle_ticket_fragment_invoice'"
              src="/img/games/puzzle.webp"
              class="ofi"
              style="width: 60px; height: 60px"
            />
            <!-- <IconPuzzle
              v-if="boost.type === 'raffle_ticket_fragment_invoice'"
              style="width: 33px; height: 33px"
            /> -->
            <img
              v-if="boost.type === 'catch_the_bull_game_additional_try_boost'"
              src="/img/games/navigation_try.webp"
              class="ofi"
              style="width: 60px; height: 60px"
            />
            <!-- <IconReturn
              v-if="boost.type === 'catch_the_bull_game_additional_try_boost'"
              class="text-blue"
              style="width: 33px; height: 33px"
            /> -->
          </div>
          <div class="shop-items-cost">
            <IconStar style="width: 16px; height: 16px" />
            <div class="shop-items-cost-value">{{ boost.priceForSingleBatch }}</div>
          </div>
        </div>
      </div>
    </Container>

    <DialogModal
      v-if="selectedBoost"
      @close="selectedBoost = null"
      :title="t(selectedBoost.name)"
      :description="t(selectedBoost.description)"
    >
      <div class="count-text">{{ t('catchTheBullGame.quantity') }}: {{ quantityToBuy }}</div>

      <div
        class="flex items-center gap-1 ma-auto count-input relative"
        style="max-width: 100%; margin-top: 20px"
      >
        <button :disabled="!availableFroPurchase" @click="decreaseItemsCount" class="count-btn">
          -
        </button>
        <input
          :disabled="!availableFroPurchase"
          type="range"
          name=""
          id=""
          v-model="quantityToBuy"
          :min="1"
          :max="selectedBoost.batchSizeMax / selectedBoost.batchStep"
          :step="1"
        />
        <button :disabled="!availableFroPurchase" @click="increaseItemsCount" class="count-btn">
          +
        </button>
        <img src="/img/games/slider.webp" alt="" class="ofi-a" />
      </div>
      <BtnPrimary
        block
        big
        class="mt-8"
        :loading="buying"
        :disabled="!availableFroPurchase"
        @click="buyBoost(selectedBoost)"
      >
        <IconStar class="text-yellow" style="width: 16px; height: 16px" />
        {{ selectedBoost.priceForSingleBatch * quantityToBuy }}
      </BtnPrimary>
    </DialogModal>
  </FullWindowModal>
</template>

<style scoped>
.shop-items-container {
  margin-top: 60px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.shop-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px 28px;
  gap: 10px;
  border-radius: 12px;
}
.shop-item.boost {
  border: 1px solid #a5a712;
  background: rgba(165, 167, 18, 0.15);
}
.shop-item.down {
  border: 1px solid #989ae8;
  background: rgba(152, 154, 232, 0.15);
}
.shop-item.bombs {
  border: 1px solid #dd797d;
  background: rgba(221, 121, 125, 0.15);
}
.shop-item.fragments {
  border: 1px solid #84a860;
  background: rgba(132, 168, 96, 0.15);
}
.shop-item.try {
  border: 1px solid #79b5dd;
  background: rgba(121, 181, 221, 0.15);
}
.shop-items-icon {
  width: 50px;
  height: 50px;
}
.shop-item.boost .shop-items-icon,
.shop-item.boost .shop-items-cost {
  color: var(--yellow);
}
.shop-item.bombs .shop-items-icon,
.shop-item.bombs .shop-items-cost {
  color: #ff9093;
}
.shop-item.fragments .shop-items-icon,
.shop-item.fragments .shop-items-cost {
  color: var(--primary);
}
.shop-item.try .shop-items-icon,
.shop-item.try .shop-items-cost {
  color: #9df0ff;
}
.shop-item.down .shop-items-cost svg {
  color: #a9aaff;
}
.shop-items-title {
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.005em;
}

.shop-items-cost-value {
  font-family: Urbanist;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.6px;
  letter-spacing: 0.005em;
}

.shop-items-cost {
  margin-top: -16px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.count-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: transparent;
  color: transparent;
  outline: none;
  /* background: linear-gradient(123deg, #d1ffa6 6.07%, #71be2b 82.15%); */
}

.count-input img {
  z-index: -1;
  /* background-image: url('/img/games/slider.webp'); */
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  background: transparent;
  /* background: linear-gradient(89.9deg, #c1c300 0.08%, #feffc8 121.2%); */
  border-radius: 2px;
  height: 0.5px;
  outline: none;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -15px; /* Centers thumb on the track */
  /* transform: translateY(-50%); */
  /* background-color: #ffd700; */
  /* background-color: var(--yellow); */
  border-radius: 4px;
  height: 25px;
  width: 22px;
  background: url('/img/games/slider_thumb.webp') no-repeat center center;

  background-size: contain;
  border: none; /* Optional: Remove border */
  cursor: pointer;
}

/*********** Firefox styles ***********/
/* slider track */
input[type='range']::-moz-range-track {
  background: transparent;
  /* background: linear-gradient(89.9deg, #c1c300 0.08%, #feffc8 121.2%); */
  border-radius: 2px;
  height: 0.5px;
  outline: none;
}

/* slider thumb */
input[type='range']::-moz-range-thumb {
  /* background-color: #ffd700; */
  /* background-color: var(--yellow); */
  border: none; /*Removes extra border that FF applies*/
  border-radius: 4px;
  height: 25px;
  width: 22px;
  background: url('/img/games/slider_thumb.webp') no-repeat center center;
  background-size: contain;
  cursor: pointer;
}

input[type='range']:focus::-moz-range-thumb {
  /* outline: 3px solid #ffd700; */
  /* outline: 3px solid var(--yellow); */
  outline-offset: 0.125rem;
}

/* ///// */

input[type='range']::-ms-thumb {
  /* background-color: #ffd700; */
  width: 22px;
  height: 25px;
  background: url('/img/games/slider_thumb.webp') no-repeat center center;
  background-size: contain;
  border: none;
  cursor: pointer;
}

.count-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  font-size: 20px;
  font-weight: bold;
}
</style>
