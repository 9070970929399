<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import TabsPanel from '../common/TabsPanel.vue'
import CurrentEventPage from './CurrentEventPage.vue'
import EventHistory from './EventHistory.vue'
import {
  useGameEventsStore,
} from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'

const TAB_CURRENT = 'eventTab.tabs.current'
const TAB_HISTORY = 'eventTab.tabs.history'

const currentTab = ref(TAB_CURRENT)

const tabs = reactive([
  { id: 1, title: TAB_CURRENT },
  { id: 2, title: TAB_HISTORY }
])
// Current - display the current tournaments page/flow (registration, missed registration, tournaments screen);
// History - display the list of completed events by date with the ability to view winners table.

const props = defineProps<{ tapTournamentId: number }>()

const tapTournamentStore = useGameEventsStore()

const { tournamentRound } = storeToRefs(tapTournamentStore)


onMounted(() => {
  tapTournamentStore.getTapTournamentRound(props.tapTournamentId)
})
</script>

<template>
  <div class="">
    <TabsPanel class="my-7" :tabs="tabs" v-model:tab="currentTab" />

    <CurrentEventPage v-if="currentTab == TAB_CURRENT && tournamentRound !== null" />
    <EventHistory v-if="currentTab == TAB_HISTORY" />
  </div>
</template>
