<script setup lang="ts">
import { useGameEventsStore } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import WinnersList from './WinnersList.vue'

const { t } = useI18n()

const gameEventStore = useGameEventsStore()
const { getPhaseUsers } = gameEventStore
const { phaseUsers, fetchingResults } = storeToRefs(gameEventStore)
const phaseId = 8

onMounted(async () => {
  await getPhaseUsers(phaseId)
})
</script>

<template>
  <div class="">
    <div style="max-width: 95%" class="ma-auto">
      <h2 class="mt-4 page-title" v-html="t('eventTab.title')"></h2>

      <h3 class="page-sub-title mt-4">{{ t('eventTab.description') }}</h3>
    </div>
    <!-- - Your Rank → check Hamster Combat for reference.  -->

    <!-- - Leaderboard. -->
    <div class="">
      <WinnersList :users="phaseUsers" :loading="fetchingResults" />
    </div>
  </div>
</template>

<style scoped></style>
